<template>
  <div>
    <b-breadcrumb class="mb-1">
      <b-breadcrumb-item href="/">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item v-on:click="goBack">Factures</b-breadcrumb-item>
      <b-breadcrumb-item active>Édition de Facture</b-breadcrumb-item>
    </b-breadcrumb>

    <b-card>
      <b-overlay :show="loading" rounded="sm">
        <b-card no-body class="invoice-preview-card">
          <b-card-body class="invoice-padding pb-0">
            <div
              class="
                d-flex
                justify-content-between
                flex-md-row flex-column
                invoice-spacing
                mt-0
              "
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img
                    :src="enterprise.logo"
                    width="120"
                    alt="placeholder"
                  ></b-img>
                  <h3 class="text-primary invoice-logo">
                    {{ enterprise.name }}
                  </h3>
                </div>

                <b-card-text class="mb-25"> Cotonou -Benin </b-card-text>
              </div>

              <!-- Header: Right Content -->
              <div class="invoice-number-date mt-md-0 mt-2">
                <div
                  class="d-flex align-items-center justify-content-md-end mb-1"
                >
                  <h4 class="invoice-title">Facture N° :</h4>
                  <b-form-group
                    class="input-group-merge invoice-edit-input-group disabled"
                  >
                    <b-form-input
                      disabled
                      v-model="invoice.num"
                      id="invoice-data-id"
                    />
                    <small
                      v-show="showErrors"
                      class="text-danger"
                      v-if="invoice.num == '00000'"
                      >Veuillez renseigner le numéro de la facture</small
                    >
                  </b-form-group>
                </div>
                <div
                  class="d-flex align-items-center justify-content-md-end mb-1"
                >
                  <span class="title"> Date: </span>
                  <flat-pickr
                    v-model="invoice.created_at"
                    class="form-control col-5 invoice-edit-input"
                    disabled
                  />
                </div>
                <div
                  class="d-flex align-items-center justify-content-md-end mb-1"
                >
                  <span class="title"> Date d'écheance : </span>
                  <flat-pickr
                    type="date"
                    v-model="invoice.due_date"
                    class="form-control col-5 invoice-edit-input"
                  />
                </div>
              </div>
            </div>
          </b-card-body>
          <hr class="invoice-spacing" />
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <b-col cols="12" xl="6" class="mb-lg-1">
                <h6 class="mb-2">
                  <b>Client : </b>
                </h6>
                <div class="mt-1">
                  <validation-observer ref="editCustomerForm">
                    <b-form>
                      <b-card-text class="mb-25"
                        ><span class="d-flex justify-content-between"
                          ><b>Nom : </b>
                          <b-input
                            :readonly="editCustomer"
                            class="col-md-8 ky-input"
                            v-model="customer.name"
                            size="sm"
                          />
                        </span>
                      </b-card-text>
                      <b-card-text class="mb-25">
                        <span class="d-flex justify-content-between"
                          ><b>Adresse : </b>
                          <b-input
                            :readonly="editCustomer"
                            class="col-md-8 ky-input"
                            v-model="customer.address"
                            size="sm"
                          />
                        </span>
                      </b-card-text>
                      <b-card-text class="mb-25"
                        ><span class="d-flex justify-content-between"
                          ><b>Téléphone : </b>
                          <b-input
                            :readonly="editCustomer"
                            class="col-md-8 ky-input"
                            v-model="customer.telephone"
                            size="sm"
                          />
                        </span>
                      </b-card-text>
                      <b-card-text class="mb-0"
                        ><span class="d-flex justify-content-between"
                          ><b>E-mail : </b>
                          <b-input
                            :readonly="editCustomer"
                            class="col-md-8 ky-input"
                            v-model="customer.email"
                            size="sm"
                          />
                        </span>
                        <br />

                        <hr />
                        <span class="d-flex justify-content-between">
                          <b-link
                            v-if="editCustomer == false"
                            @click="editCustomer = !editCustomer"
                            >Annuler</b-link
                          >
                          <b-link
                            class="text-success"
                            v-if="editCustomer == false"
                            @click="editThisCustomer()"
                          >
                            Sauvegarder</b-link
                          >
                          <b-link
                            v-if="editCustomer == true"
                            @click="editCustomer = !editCustomer"
                            >Modifier ce client
                          </b-link>
                        </span>
                      </b-card-text>
                    </b-form>
                  </validation-observer>
                </div>
              </b-col>

              <!-- Col: Payment Details -->
            </b-row>

            <div class="col-md-3" style="float: right">
              <validation-provider
                #default="{ errors }"
                :rules="'required'"
                name="TVA"
              >
                <b-form-group label="TVA(%)">
                  <b-form-input type="number" v-model="invoice.tva" />
                  <small class="text-danger"> {{ errors[0] }} </small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :rules="'required'"
                name="TVA"
              >
                <b-form-group label="Remise(%)">
                  <b-form-input type="number" v-model="invoice.discount" />
                  <small class="text-danger"> {{ errors[0] }} </small>
                </b-form-group>
              </validation-provider>
            </div>
          </b-card-body>

          <div class="ml-1 mr-0 justify-content-center"></div>

          <div class="card">
            <div class="card-body">
              <span v-if="isEmpty == true" class="text-danger"
                >Veuillez associer des prestations à cette facture</span
              >
              <edit-invoice-all-customer-tasks
                class="mb-4"
                :tasksData="tasks"
                :customer="customer"
                :price="invoice.price"
                :tva="invoice.tva"
                :totalDiscount="totalDiscount"
                :invoiceTotal="invoiceTotal"
                :invoiceTTC="invoiceTTC"
                ref="billableTasks"
              />
              <div class="row">
                <div class="col-md-4">
                  <b-btn
                    @click="editThisInvoice"
                    :disabled="generateIsProgress"
                  >
                    <b-spinner v-if="generateIsProgress" small />
                    <b-icon icon="download" v-else /> Sauvegarder
                  </b-btn>
                </div>
              </div>
            </div>
          </div>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
        </b-card>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import kyInlineInput from "@/@core/layouts/components/ky-inline-input.vue";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import Multiselect from "vue-multiselect";
import KyAutocomplete from "@/@core/layouts/components/ky-autocomplete.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import EditInvoiceAllCustomerTasks from "../../invoice/EditInvoiceAllCustomerTasks.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { BToast } from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { map } from "postcss-rtl/lib/affected-props";
export default {
  components: {
    kyInlineInput,
    Multiselect,
    KyAutocomplete,
    EditInvoiceAllCustomerTasks,
    BToast,
    ToastificationContent,
    KyInput,
    flatPickr,
    vSelect,
  },

  data() {
    return {
      loading: true,
      tasks: [],
      editCustomer: true,
      isEmpty: false,
      canGenerate: false,
      enterprise: {},
      generateIsProgress: false,
      uploaded: false,
      imageUrl: null,
      showErrors: false,
      errors: "",
      theSum: 0,
      invoice: {
        due_date: null,
        num: "",
        tva: 20,
        tasks: [
          {
            id: 0,
            price: 0,
          },
        ],
        customer: {
          id: "",
          name: "",
          address: "",
          telephone: 0,
          email: "",
        },
      },

      client: {
        id: "",
        name: "",
        telephone: "",
        logo: "",
        email: "",
        website: "",
        matricule: "",
        address: "",
      },

      total: 0,
      state: 0,
      customer: {
        name: "",
      },
    };
  },

  computed: {
    ...mapState("tasks", ["billableTasks", "tasksWithoutCustomer", "facture"]),
    ...mapState("enterprise", { customers: (state) => state.clients }),

    isEmailRequired() {
      if (this.client.telephone == "") {
        return "required";
      }
    },
    isTelephoneRequired() {
      if (this.client.email == "") {
        return "required";
      }
    },
  },

  methods: {
    ...mapActions("enterprise", ["getInfo", "updateCustomer"]),
    ...mapActions("tasks", [
      "taskInvoice",
      "showThisInvoice",
      "generateInvoiceFile",
    ]),

    async editThisCustomer() {
      const valid = await this.$refs.editCustomerForm.validate();
      if (!valid) return;
      this.updateCustomer(this.$formData(this.customer))
        .then((response) => {
          this.editCustomer = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Client modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Données existantes",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    onFileUpload(event) {
      this.uploaded = true;
      var file = event.target.files[0];
      if (file) this.imageUrl = URL.createObjectURL(file);

      this.client.logo = file;
      this.client.logo_only = 1;
    },
    openFilePicker() {
      document.getElementById("logo").click();
    },

    goBack() {
      history.back();
    },

    totalDiscount() {
      // calculate the total discount
      var sum = 0;
      for (let item = 0; item < this.tasks.length; item++) {
        if (this.tasks[item].price == null) {
          return "0";
        } else {
          sum += Number(this.tasks[item].price);
        }
      }
      var discountAmount = (sum * this.invoice.discount) / 100;
      return discountAmount
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    invoiceTotal() {
      var sum = 0;
      for (let item = 0; item < this.tasks.length; item++) {
        if (this.tasks[item].price == null) {
          return "0";
        } else {
          sum += Number(this.tasks[item].price);
        }
      }
      this.theSum = sum - (sum * this.invoice.discount) / 100;
      return sum
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    invoiceTTC() {
      var myTTC = this.theSum + (this.theSum * this.invoice.tva) / 100;
      return myTTC
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    editThisInvoice() {
      this.generateIsProgress = true;
      var formData = new FormData();
      formData.append("tasks", JSON.stringify(this.invoice));
      this.generateInvoiceFile(this.invoice)
        .then((e) => {
          this.generateIsProgress = false;
          // this.$router.push({ name: "admin-invoices" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Facture générée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.generateIsProgress = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Données invalides",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },

  watch: {
    facture(val) {
      if (val.is_validate == 1) {
        this.invoice.num = val.num;
      }
      this.invoice = val;
      this.customer = val.customer;
      this.tasks = val.tasks;
    },
  },

  mounted() {
    this.showThisInvoice(this.$route.params.id).then((e) => {
      this.loading = false;
    });
    this.tasks;
    this.getInfo().then((r) => {
      this.enterprise = r.data.data;
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>

<style lang="scss" scoped>
%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #f8f4f4;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: silver;
  }
}

// input[type="text"] {
//   border: none;
//   border-bottom: 1px dotted grey;
//   min-width: 280px;
//   outline: none;
//   color: #6e6b7b;
//   font-weight: 500;
// }

// .form-group > label {
//   top: 18px;
//   left: 6px;
//   position: relative;
//   background-color: white;
//   padding: 0px 5px 0px 5px;
//   font-size: 0.9em;
// }

.multiselect {
  width: 50%;
}

::v-deep.multiselect__tags {
  border-radius: 0px;
  margin: 0px;
  border: none !important;
  border-bottom: 1px dotted grey;
  max-width: 100%;
  outline: none;
}

.custom-auto {
  top: 25px;
  max-height: 150px;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  position: static;
  @extend %scrollbar;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style scoped>
.ky-input {
  border: none;
}
</style>