<template>
  <div>
    <input
      type="text"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      autocomplete="off"
    />
  </div>
</template>


<script>
export default {
  props: ["value", "placeholder"],

  data() {
    return {};
  },

  methods: {},
};
</script>
<style scoped>
input[type="text"] {
  border: none;
  border-bottom: 0.1px dotted #6e6b7b;
  min-width: 280px;
  outline: none;
  color: #6e6b7b;
  font-weight: 500;
}
</style>