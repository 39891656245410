<template>
  <div>
    <input
      id="link-button"
      type="text"
      :value="label ? selected[label] : selected"
      v-on:input="$emit('input',$event.target.value)"
      :placeholder="placeholder"
    />
    <b-popover target="link-button" title="Liste des clients" triggers="focus">
      <div class="custom-auto">
        <b-list-group>
          <b-list-group-item
            v-for="item in customers"
            :key="item.id"
            :active="selected.id === item.id"
            @click="select(item)"
          >
            {{ item.name }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-popover>
    <!-- <div v-if="popover" class="custom-auto">
      <div class="custom-auto-list">
        <div
          v-for="(item, index) in filteredItems"
          class="custom-auto-item"
          :key="index"
          @click="select(item)"
        >
          <div class="custom-auto-item-text">
            {{ label ? item[label] : item }}
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>


<script>
export default {
  props: ["placeholder", "items", "label"],

  data() {
    return {
      popover: false,
      selected: {},
      inputValue: "",
     
      increment: 0,

      customers:[]
    };
  },

  methods: {
    select(item) {
      this.selected = item;
      this.$emit("input", item);
      this.popover = false;
    },

   
  },

  computed: {},

  mounted(){
   
    
    this.customers= this.items
  }
};
</script>
<style scoped lang="scss">
%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #f8f4f4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: silver;
  }
}
input[type="text"] {
  border: none;
  border-bottom: 1px dotted grey;
  max-width: 150px;
  outline: none;
}
.custom-auto {
  top: 25px;
  max-height: 150px;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  position: static;
  @extend %scrollbar;
}

.custom-auto-item {
  padding: 5px;
  cursor: pointer;
  background: yellowgreen;
  border-bottom: 2px solid grey;
}


</style>